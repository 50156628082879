<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <dashbord-sidebar />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn
                icon
                @click="isSidebar = !isSidebar"
              >
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex align-center mb-5">
                <v-avatar
                  tile
                  size="25"
                  class="me-3"
                >
                  <img
                    src="@/assets/images/icons/bag_filled.svg"
                    alt=""
                  >
                </v-avatar>
                <h2 class="mb-0">
                  Đấu giá của tôi
                </h2>
              </div>
              <div class="d-none px-4 d-md-flex mb-5">
                <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
                  Mã đấu giá
                </h4>
                <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
                  Trạng thái
                </h4>
                <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
                  Ngày đấu giá
                </h4>
                <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
                  Tổng cộng
                </h4>
                <div class="px-5" />
              </div>

              <v-data-iterator
                :items="items"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="(item,index) in props.items"
                      :key="index"
                      cols="12"
                    >
                      <a
                        href="#"
                        class="text-decoration-none"
                      >
                        <base-card class="">
                          <div class="pa-4 d-flex align-center flex-wrap">
                            <div class="flex-1 mr-3">
                              <h4 class="font-600 mb-0 ">{{ item.MaDG }}{{ (index+1) }}</h4>
                            </div>
                            <div class="flex-1 mr-3">
                              <v-chip
                                class="ma-2"
                                :color="status[item.Status].color"
                                small
                                dark
                              >
                                {{ status[item.Status].text }}
                              </v-chip>
                            </div>
                            <div class="flex-1 mr-3">
                              <p class="mb-0">
                                {{ tz(item.TG).format("MM/DD/YYYY HH:MM") }}
                              </p>
                            </div>

                            <div class="flex-1 mr-3">
                              <p class="mb-0">
                                {{ jpy(item.TC) }}
                              </p>
                            </div>
                            <v-btn
                              icon
                              color="grey"
                              class=""
                            >
                              <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>
                          </div>
                        </base-card>
                      </a>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:footer>
                  <v-row
                    class="my-5 me-1"
                    align="center"
                    justify="center"
                  >
                    <v-spacer />

                    <span
                      class="mr-4
                                grey--text"
                    >
                      Page {{ page }} of {{ numberOfPages }}
                    </span>
                    <v-btn
                      fab
                      :disabled="page == 1"
                      small
                      color="primary"
                      class="mr-1"

                      @click="formerPage"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab

                      :disabled="page == numberOfPages"
                      small
                      color="primary"
                      class="ml-1"
                      @click="nextPage"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//   import CardCart from '@/components/cartCard/CardCart'
  import DashbordSidebar from '@/components/DashboardSidebar.vue'
  import status from '@/plugins/daugia.js'
  import tz from 'moment-timezone'
  import CurrencyHelper from '@/plugins/helpers/currency'
  export default {
    components: {
      //   CardCart,
      DashbordSidebar,
    },
    data () {
      return {
        status,
        isSidebar: false,
        page: 1,
        itemsPerPage: 10,
        items: [
          { MaDG: 'DG00', Status: 'dang_dau_gia', TG: 'Sat Aug 07 2021 22:32:53 GMT+0700', TC: '5000' },
          { MaDG: 'DG00', Status: 'that_bai', TG: 'Sat Aug 07 2021 22:32:53 GMT+0700', TC: '0' },
          { MaDG: 'DG00', Status: 'dang_xu_ly', TG: 'Sat Aug 07 2021 22:32:53 GMT+0700', TC: '5000' },
          { MaDG: 'DG00', Status: 'cho_thanh_toan', TG: 'Sat Aug 07 2021 22:32:53 GMT+0700', TC: '2000' },
          { MaDG: 'DG00', Status: 'cho_xac_nhan', TG: 'Sat Aug 07 2021 22:32:53 GMT+0700', TC: '3000' },
          { MaDG: 'DG00', Status: 'da_xac_nhan', TG: 'Sat Aug 07 2021 22:32:53 GMT+0700', TC: '5400' },
        ],
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],

      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      ...CurrencyHelper,
      tz,
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
    },
  }
</script>
