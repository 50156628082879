const status = {
  dang_dau_gia: {
    text: 'Đang đấu giá',
    color: 'green',
  },
  that_bai: {
    text: 'Thất bại',
    color: 'grey lighten-2',
  },
  dang_xu_ly: {
    text: 'Đang xử lí',
    color: 'blue lighten-2',
  },
  cho_thanh_toan: {
    text: 'Chờ thanh toán',
    color: 'blue lighten-1',
  },
  cho_xac_nhan: {
    text: 'Chờ xác nhận',
    color: 'blue darken-1',
  },
  da_xac_nhan: {
    text: 'Đã xác nhận',
    color: 'green darken-1',
  },
}
export default status
